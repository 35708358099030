<template>
<div>
    <b-row class="match-height">
        <!-- personal detail block -->
        <b-col cols="12" md="7" lg="8">
            <b-card>

                <b-row>
                    <b-col cols="12" lg="2" v-if="user.profile_image != null || user.default_signature != null">
                        <b-row>
                            <b-col cols="6" md="12" lg="12" v-if="user.profile_image != null">
                                <img :src="user.profile_image" class="rounded img-fluid" style="width: auto; height: 100px;">
                            </b-col>
                            <b-col cols="6" md="12" lg="12" v-if="user.default_signature != null">
                                <img :src="user.default_signature" class="rounded img-fluid bg-white mt-2" style="width: auto; height: 100px;">
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col cols="12" lg="10">
                        <b-row>
                            <b-col cols="12" lg="4">
                                <div class="mb-1">
                                    <h4 class="mb-0"> {{user.full_name}} </h4>
                                    <span class="card-text">{{user.email}}</span>
                                </div>
                                <div class="d-flex flex-wrap">
                                    <b-button variant="outline-danger" @click="$router.go(-1)"> Back </b-button>
                                    <b-button variant="primary" class="ml-1" :to="{ path: '/client/edit-profile' }"> Edit </b-button>
                                </div>
                            </b-col>
                            <b-col cols="12" lg="8">
                                <b-row>
                                    <b-col cols="6" class="pb-1"><feather-icon icon="UserIcon" class="mr-25"/> Username </b-col>
                                    <b-col cols="6" class="pb-1"> {{user.username}} </b-col>
                                    <b-col cols="6" class="pb-1"><feather-icon icon="HeartIcon" class="mr-25"/> Gender </b-col>
                                    <b-col cols="6" class="pb-1"> {{user.gender | capitalize}} </b-col>
                                    <b-col cols="6" class="pb-1"><feather-icon icon="BriefcaseIcon" class="mr-25"/> Account Type </b-col>
                                    <b-col cols="6" class="pb-1"> {{user.role | capitalize}} </b-col>
                                    <b-col cols="6" class="pb-1"> <feather-icon icon="PhoneIcon" class="mr-25"/> {{user.phone_number}} </b-col>
                                    <b-col cols="6" class="pb-1"> {{user.phone_number}} </b-col>
                                    <b-col cols="6" class="pb-1"> <feather-icon icon="PlusCircleIcon" class="mr-25"/> Registered On </b-col>
                                    <b-col cols="6" class="pb-1"> <!-- {{user.created_at | dateTime}} </b-col> -->
                                    <span v-if="user.role == 'administrator' || user.role == 'admin'">{{user.created_at | dateTime}}</span> 
                                    <span v-else-if="user.role == 'site_client'">{{sitedateTime(user.created_at,user.site.pdf_date_format,user.site.pdf_time_format)}}</span>
                                    <span v-else>{{sitedateTime(user.created_at,user.om_sites[0].pdf_date_format,user.om_sites[0].pdf_time_format)}}</span>
                                    </b-col>

                                    <!-- <b-col cols="6" class="pb-1" v-if="user.role == 'supervisor'"> <feather-icon icon="CreditCardIcon" class="mr-25"/> Allowed NFC Mode</b-col>
                                    <b-col cols="6" class="pb-1" v-if="user.role == 'supervisor'"> {{user.nfc_mode | capitalize}} </b-col> -->

                                    <!-- <b-col cols="6" class="pb-1" v-if="user.company_name != '' && user.company_name != null"> <feather-icon icon="HomeIcon" class="mr-25"/> Company Name</b-col>
                                    <b-col cols="6" class="pb-1" v-if="user.company_name != '' && user.company_name != null"> {{user.company_name | capitalize}} </b-col> -->
                                </b-row>
                            </b-col>
                        </b-row>
                    </b-col>

                </b-row>
            </b-card>
        </b-col>

        <!-- device and login detail block -->
        <b-col cols="12" md="5" lg="4" v-if="user.last_login && user.last_login_platform">
            <b-card>
                <b-row>
                    <b-col cols="12">
                        <b-row>
                            <b-col cols="12" class="pb-1"> <h4> Device and Login Details </h4> </b-col>
                            <b-col cols="6" class="pb-1"> Last login </b-col>
                            <b-col cols="6" class="pb-1"> {{user.last_login | dateTime}} </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="6" class="pb-1 padding_bottom_zero "> Platform </b-col>
                            <b-col cols="6" class="pb-1 padding_bottom_zero"> {{user.last_login_platform | capitalize}} </b-col>
                        </b-row>
                        <b-row v-if="device_details">
                            <b-col cols="6" class="pb-1"> Device Type </b-col>
                            <b-col cols="6" class="pb-1" v-if="device_details.device_type"> {{device_details.device_type | capitalize}} </b-col>
                            <b-col cols="6" class="pb-1" v-else> N/A </b-col>
                        </b-row>
                        <b-row v-if="device_details">
                            <b-col cols="6" class="pb-1"> Application Version </b-col>
                            <b-col cols="6" class="pb-1" v-if="device_details.app_version"> {{device_details.app_version}} </b-col>
                            <b-col cols="6" class="pb-1" v-else> N/A </b-col>
                        </b-row>
                        <b-row v-if="device_details">
                            <b-col cols="6" class="pb-1"> Model Name </b-col>
                            <b-col cols="6" class="pb-1" v-if="device_details.model_name"> {{device_details.model_name | capitalize}} </b-col>
                            <b-col cols="6" class="pb-1" v-else> N/A </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </b-card>
        </b-col>

        <!-- Organization Detail block -->
        <b-col v-if="user.organization" cols="12" lg="6">
            <b-card>
                <b-row>
                    <b-col cols="12">
                        <b-row>
                            <b-col cols="12" class="pb-1"> <h4> Organization Detail </h4> </b-col>
                            <b-col cols="6" class="pb-1"> Organization Name </b-col>
                            <b-col cols="6" class="pb-1"> {{user.organization.organization_name}} </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="6" class="pb-1"> Address </b-col>
                            <b-col cols="6" class="pb-1"> {{user.organization.address}} </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="6" class="pb-1"> Postal Code </b-col>
                            <b-col cols="6" class="pb-1"> {{user.organization.postal_code}} </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="6" class="pb-1 padding_bottom_zero"> Country </b-col>
                            <b-col cols="6" class="pb-1 padding_bottom_zero"> {{user.organization.country}} </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </b-card>
        </b-col>

        <!-- Organization Billing Detail block -->
        <b-col v-if="user.organization" cols="12" lg="6">
            <b-card>
                <b-row>
                    <b-col cols="12">
                        <b-row>
                            <b-col cols="12" class="pb-1"> <h4> Organization Billing Detail </h4> </b-col>
                            <b-col cols="6" class="pb-1"> Address </b-col>
                            <b-col cols="6" class="pb-1"> {{user.organization.billing_address}} </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="6" class="pb-1"> Postal Code </b-col>
                            <b-col cols="6" class="pb-1"> {{user.organization.billing_postal_code}} </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="6" class="pb-1 padding_bottom_zero"> Country </b-col>
                            <b-col cols="6" class="pb-1 padding_bottom_zero"> {{user.organization.billing_country}} </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </b-card>
        </b-col>
        
        <!-- Menu Permissions block -->
        <b-col cols="12" md="7" lg="8" v-if="user.role != 'attendance' && user.role != 'panel'">
            <b-card no-body v-if="user.menu_permission">
                <b-card-header>
                    <div class="d-flex align-items-center">
                        <feather-icon size="24" class="mr-1" icon="ListIcon"/>
                        <b-card-title class="ml-25"> Menu Permission </b-card-title>
                    </div>
                </b-card-header>

                <b-card-body v-if="checkMenus(user.menu_permission)">

                    <app-timeline>

                        <app-timeline-item variant="primary" v-for="(menu, index) in user.menu_permission" :key="index" v-if="showHideMenu(menu.title, 'parent') && menu.access">
                            <div v-if="showHideMenu(menu.title, 'parent')" class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1">
                                <h6>{{menu.title}}</h6>
                            </div>

                            <!-- Level 1 -->
                            <div class="pl-2" v-if="menu.level == 1">
                                <div class="d-inline mr-1" v-for="(permission, ip) in menu.permissions" :key="ip" v-if="showHideMenuPermission(menu.title, permission.label) && menu.access">
                                    <span class="badge badge-pill badge-secondary mt-1"> {{permission.label}} </span>
                                </div>
                            </div>

                            <!-- Level 2 -->
                            <div class="pl-2" v-if="menu.level == 2">
                                <div class="mb-1" v-for="(child, ch) in menu.children" :key="ch" v-if="child.access">
                                    <span v-if="showHideMenu(child.title, 'child')"> {{child.title}} </span> <br>
                                
                                    <div class="ml-2">
                                        <div class="d-inline mr-1" v-for="(permission, ip) in child.permissions" :key="ip" v-if="showHideMenuPermission(child.title, permission.label) && permission.access">
                                            <span class="badge badge-pill badge-secondary mt-1"> {{permission.label}} </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <!-- Level 3 -->
                            <div class="pl-2" v-if="menu.level == 3">
                                <div class="mb-1" v-for="(child, ch) in menu.children" :key="ch" v-if="child.access">
                                    <span v-if="showHideMenu(child.title, 'child')"> {{child.title}} </span> <br>

                                    <!-- having permission -->
                                    <div class="ml-2">
                                        <div class="d-inline mr-1" v-for="(permission, ip) in child.permissions" :key="ip" v-if="showHideMenuPermission(child.title, permission.label) && permission.access">
                                            <span class="badge badge-pill badge-secondary mt-1"> {{permission.label}} </span>
                                        </div>
                                    </div>
                                    
                                    <!-- having children -->
                                    <div class="pl-2 row" v-if="child.children">
                                        <div class="col-12 pt-1" v-for="(ch, ic) in child.children" :key="ic" v-if="showHideMenuPermissionL3(menu.title, child.title, ch.title) && ch.access">
                                            <span>{{ch.title}}</span> <br>
                                            <!-- permissions of child -->
                                            <div class="pl-2 row" v-if="ch.permissions">
                                                <div class="d-inline mr-1" v-for="(pm, ipc) in ch.permissions" :key="ipc" v-if="pm.access">
                                                    <span class="badge badge-pill badge-secondary mt-1"> {{pm.label}} </span>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>

                        </app-timeline-item>

                    </app-timeline>

                </b-card-body>

                <b-card v-else>
                    <h5 class="text-center">No Menus Assigned</h5>
                </b-card>
            </b-card>
        </b-col>

    </b-row>

</div>
</template>

<script>

import {
    BCard, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,BAlert,BFormSelect,BFormSelectOption,BFormText,BInputGroupPrepend,BInputGroup,BMedia,BAvatar,BTable,BModal, BFormRadio,BInputGroupAppend, BCardBody, BCardHeader, BCardTitle, BImg, BMediaBody, BMediaAside, BAvatarGroup
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { GET_API, POST_API } from "../../../store/actions.type"
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
export default {
    name: 'ClientView',
    mixins: [togglePasswordVisibility],
    computed: {
        passwordToggleIcon() {
        return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
        },
    },
    components: {
        BAlert,
        BCard,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BFormCheckbox,
        BForm,
        BButton,
        BFormSelect,
        BFormSelectOption,
        BFormText,
        BInputGroupPrepend,
        BInputGroup,
        BAvatar,
        BTable,
        BModal,
        VueCropper,
        BFormRadio,
        BInputGroupAppend,
        BImg,
        BCardBody,
        BCardHeader,
        BCardTitle,
        AppTimeline,
        AppTimelineItem,
        BMedia,
        BMediaBody,
        BMediaAside,
        BAvatarGroup,
    },
    directives: {
        Ripple
    },
    data() {
        return {
            user: {},
            device_details:null,
        }
    },
    methods : {
        userDetail(){
            return this.$store.dispatch(POST_API, {
                data:{
                    id: this.$store.getters.currentUser._id,
                    with_detail: true,
                },
                api: '/api/client-user-detail'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showDismissibleAlert = true;
                    window.scrollTo(0,0);
                } else {
                    this.user = this.$store.getters.getResults.data;

                    if (this.user.default_signature != null) {
                        this.user.default_signature = this.user.default_signature + '?' + new Date()
                    }
                }
            });
        },

        deviceDetails(){
            return this.$store.dispatch(POST_API, {
                data:{
                    id: this.$route.params.id,
                },
                api: '/api/device-details'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showDismissibleAlert = true;
                    window.scrollTo(0,0);
                } else {
                    this.device_details = this.$store.getters.getResults.data;
                }
            });
        },
    },
    mounted(){
        this.userDetail().then(() => {
            if(this.user.last_login && this.user.last_login_platform){
                this.deviceDetails();
            }
        });
    }
}
</script>
