<template>
    <div>
    	<template>
          <div id="breadcrumb-alignment" class="mb-1">
            <div class="d-flex justify-content-start breadcrumb-wrapper">
              <b-breadcrumb
                :items="breadCrumb()"
              />
            </div>
          </div>
        </template>

        <AdminView v-if="this.$store.getters.currentUser.role == 'admin'" />
        <ClientView v-else />
    </div>
</template>
<script>
import {
    BBreadcrumb
} from 'bootstrap-vue'
import AdminView from './AdminView.vue';
import ClientView from './ClientView.vue';
export default {
    components: { AdminView, ClientView,BBreadcrumb },
    methods : {
    	breadCrumb(){
          var item = [{
            to:{name:'client-dashboard'},
            text: 'Dashboard',
          },{
            to:null,
            text: 'Profile',
            active:true
          }];
          return item;
        }
    }
}

</script>
